/* all routes */
const GideAuthLayout = () => import("../layouts/AuthLayouts/GideAuthLayout.vue")
const GideLayout = () => import("../layouts/GideLayout.vue")
const ContentLayout = () => import("../layouts/ContentLayout.vue")
const WebflowLayout = () => import("../layouts/WeflowLayout.vue") // To be Used on the website

const Login = () => import("../views/Login.vue")
const LoginV1 = () => import("../views/LoginV1.vue")
const Register = () => import("../views/Register.vue")
const RegisterV1 = () => import("../views/RegisterV1.vue")
const Activate = () => import("../views/Activate.vue")
const ActivateV1 = () => import("../views/ActivateV1.vue")
const QuickProfile = () => import("../views/QuickProfile.vue")
const QuickProfileV1 = () => import("../views/QuickProfileV1.vue")
const ForgotPassword = () => import("../views/ForgotPassword.vue")
const ForgotPasswordV1 = () => import("../views/ForgotPasswordV1.vue")
const ResetPassword = () => import("../views/ResetPassword.vue")
const ResetPasswordV1 = () => import("../views/ResetPasswordV1.vue")

const Posts = () => import("../views/Gide/Post/Posts.vue")
const PostDetail = () => import("../views/Gide/Post/PostDetail.vue")
const Clubs = () => import("../views/Gide/ListPages/Club/ClubList.vue")
const UserProfile = () => import("../views/Gide/Profile/User/Profile.vue")
const UserPublicProfile = () => import("../views/Gide/Profile/User/PublicProfile.vue")
const UserProfileEdit = () => import("../views/Gide/Profile/User/ProfileEdit.vue")
const ClubProfile = () => import("../views/Gide/Profile/Club/Profile.vue")
const ClubProfileEdit = () => import("../views/Gide/Profile/Club/ProfileEdit.vue")
const ClubCreate = () => import("../views/Gide/Profile/Club/ProfileAdd.vue")
const eventView = () => import("../views/Gide/Event/EventView.vue")
const eventAdd = () => import("../views/Gide/Event/EventAdd.vue")
const eventEdit = () => import("../views/Gide/Event/EventEdit.vue")
const Events = () => import("../views/Gide/Event/EventList.vue")

const OrganisationList = () => import("../views/Gide/Organisation/OrganisationList.vue")
const OrganisationDetails = () => import("../views/Gide/Organisation/OrganisationDetails.vue")
const OrganisationEdit = () => import("../views/Gide/Organisation/OrganisationEdit.vue")

const SubscriptionPlans = () => import("../views/Gide/Subscription/SubscriptionPlans.vue")

// Paygw_status
const PaygwStatus = () => import ("../views/Gide/Paygw/Paygw_status.vue")
const Paygw = () => import ("../views/Gide/Paygw/Paygw.vue")

const Invoice = () => import ("../views/Invoice.vue")

//  Careers
const CareerList = () => import ("../views/Gide/ListPages/Career/CareerList.vue")
const CareerDetail = () => import ("../views/Gide/Profile/Career/Profile.vue")
const CareerAdd = () => import("../views/Gide/ListPages/Career/CareerAdd.vue")
const CareerEdit = () => import("../views/Gide/ListPages/Career/CareerEdit.vue")

// Content page
const PostContent = () => import("../views/Content/PostContent.vue")
const Tnc = () => import("../views/Tnc.vue")
const PrivacyPolicy = () => import("../views/PrivacyPolicy.vue")

// Public Pages
const ErrorPage = () => import("../views/Public/ErrorPage.vue")
const GideEventList = () => import("../views/Public/GideEventList.vue")
const GideEventView = () => import("../views/Public/GideEventView.vue")
const GideViewCurrentEvent = () => import("../views/Public/GideViewCurrentEvent.vue")
const WebFlowContactUsStudentsAdd = () => import("../views/Public/WebFlowContactUs.vue")
const CustomActivityForm = () => import("../views/Public/CustomActivityForm.vue")

// photos and videos
const Photos = () => import("../views/Gide/Photos.vue")

const Admission = () => import("../components/Admission.vue")

// Admssion Analytics DashBoard
const AdmissionDashboard = () => import("../views/Gide/ListPages/Admission/AdmissionDashboard.vue")
const AdmissionAnalyticsSchoolWise = () => import("../views/Gide/ListPages/Analytics/AdmissionAnalyticsSchoolWise.vue")

// Event Dashboard Report
const EventList = () => import("../components/EventList.vue")

// Report Dashboard
const EventParticipantReport = () => import("../views/Gide/Event/EventParticipantReport.vue")
const EventReportSchoolWise = () => import("../views/Gide/Event/EventReportSchoolWise.vue")

// Organisation
const OrganisationAdd = () => import("../views/Gide/Organisation/OrganisationAdd.vue")
const orgAccountDashboard = () => import("../components/OrgDashboard.vue") // If a user is a univ agent or a organisation management with multiple organisations or univs or combo under its account then show the list
const OrgModeration = () => import ("../views/Gide/Organisation/OrgModeration.vue")
const OrgSaasDashboard = () => import ("../views/Gide/Organisation/OrgSaasDashboard.vue")
const OrgSaasDomainValidate = () => import ("../views/Gide/Organisation/OrgSaasDomainValidate.vue")
const OrgSaasOrganisationEdit = () => import ("../views/Gide/Organisation/OrgSaasOrganisationEdit.vue")
const OrgSaasLoginPagePreview = () => import ("../views/Gide/Organisation/OrgSaasLoginPagePreview.vue")

// Roles(ACL)
const ManageUsers = () => import ("../components/Roles/ManageUsers.vue")

// Affiliate
const AffiliateList = () => import("../views/Gide/Affiliate/AffiliateList.vue")

// Follower Reports
const analyticsFollowerCountReport = () => import("../views/Gide/ListPages/Analytics/analyticsFollowerCountReport.vue") // To be displayed to GIDE ADMIN ONLY
const analyticsFollowerListReport = () => import("../views/Gide/ListPages/Analytics/analyticsFollowerListReport.vue") // To be displayed to UNIV ADMIN ONLY

// B2B leads Regsitration
const ContactUsOthersAdd = () => import("../views/Gide/ContactUs/UserRegLeadAdd.vue")

// Student and Parents contact us form
const ContactUsStudentsAdd = () => import("../views/Gide/ContactUs/ContactUs.vue")
const ContactUsOthersAddV1 = () => import("../views/UserRegLeadAddV1.vue")

const Confirmation = () => import("../views/Confirmation.vue")

// Contact us Form List for Parent/ Student
const ContactUsList = () => import("../views/Gide/ContactUs/contactusList.vue")

// Organisation Subscription Report
const SubscriptionReport = () => import("../views/Gide/Subscription/SubscriptionReport.vue")

// Event Checkin Report
const EventCheckinReport = () => import("../views/Gide/Event/EventCheckinReport.vue")
const EventCheckingForStudent = () => import("../views/Gide/Event/EventCheckingForStudent.vue")

// Questions
const QuestionQuiz = () => import("../views/Gide/Quiz/QuestionQuiz.vue")
const QuestionQuizV1 = () => import("../views/Gide/Quiz/QuestionQuizV1.vue")
const QuizList = () => import("../views/Gide/Quiz/QuizList.vue")
const QuizResultView = () => import("../views/Gide/Quiz/QuizResultView.vue")
const QuizDetail = () => import("../views/Gide/Quiz/QuizDetail.vue")
const AppAnswerExplanation = () => import("../components/AppAnswerExplanation.vue")

// Games
const hangman = () => import("../views/Gide/Games/HangmanGames/Hangman.vue")
const crossword = () => import("../views/Gide/Games/CrossWordGames/Crossword.vue")

// SPCM
const SpcmADD = () => import("../views/Gide/SPCM/SpcmAdd.vue")
const SpcmEdit = () => import("../views/Gide/SPCM/SpcmEdit.vue")
const SpcmList = () => import("../views/Gide/SPCM/SpcmList.vue")

// Test Prep
const TPrepHome = () => import("../views/Gide/TPrep/TPrepHome.vue")
const TPrepModuleList = () => import("../views/Gide/TPrep/TPrepModuleList.vue")
const TPrepResultsList = () => import("../views/Gide/TPrep/TPrepResultsList.vue")
const TPrepProduct = () => import("../views/Gide/TPrep/TPrepProduct.vue")
const TPrepSpecificProduct = () => import("../views/Gide/TPrep/TPrepSpecificProduct.vue")

// TprepSession
const TprepSessionList = () => import("../views/Gide/TPrep/TprepSessionList.vue")
const TprepSessionAdd = () => import("../views/Gide/TPrep/TprepSessionAdd.vue")
const TprepSessionEdit = () => import("../views/Gide/TPrep/TprepSessionEdit.vue")

// Tprep Quiz Dashboard
const TprepQuizDashboard = () => import("../views/Gide/TPrep/TprepQuizDashboard.vue")

// Analytics
const analyticsTrackerList = () => import("../views/Gide/ListPages/Analytics/analyticsTrackerList.vue")
const clickAnalytics = () => import("../views/Gide/ListPages/Analytics/ClickAnalytics.vue")

// Test Prep Config
const TPrepSubjectModuleList = () => import("../views/Gide/TPrepSubjectModule/TPrepSubjectModuleList.vue")
const TPrepSubjectModuleAdd = () => import("../views/Gide/TPrepSubjectModule/TPrepSubjectModuleAdd.vue")
const TPrepSubjectModuleEdit = () => import("../views/Gide/TPrepSubjectModule/TPrepSubjectModuleEdit.vue")

// Interest
const InterestList = () => import("../views/Gide/Interest/InterestList.vue")
const InterestAdd = () => import("../views/Gide/Interest/InterestAdd.vue")
const InterestEdit = () => import("../views/Gide/Interest/InterestEdit.vue")

// Interest Category
const InterestCategoryList = () => import("../views/Gide/Interest/InterestCategoryList.vue")
const InterestCategoryAdd = () => import("../views/Gide/Interest/InterestCategoryAdd.vue")
const InterestCategoryEdit = () => import("../views/Gide/Interest/InterestCategoryEdit.vue")

// Quiz Report List
const QuizReportList = () => import("../components/QuizReport.vue")

// Question Bank
const QuizBankList = () => import("../views/Gide/QuizBank/QuizBankList.vue")
const QuizBankAdd = () => import("../views/Gide/QuizBank/QuizBankAdd.vue")
const QuizBankEdit = () => import("../views/Gide/QuizBank/QuizBankEdit.vue")
const QuestionQuizView = () => import("../views/Gide/QuizBank/QuestionQuizView.vue")
const AppResultList = () => import("../views/Gide/AppResult/AppResultList.vue")
const AppResultListFix = () => import("../views/Gide/AppResult/AppResultListFix.vue")

const AppEdit = () => import("../views/Gide/Apps/AppEdit.vue")
const AppList = () => import("../views/Gide/Apps/AppList.vue")
const AppAdd = () => import("../views/Gide/Apps/AppAdd.vue")
const ThankYouPage = () => import("../views/Gide/ThankYouPage.vue")

// Event
const eventList = () => import("../components/EventList.vue")

// University Courses
const UniversityCourseList = () => import("../views/Gide/ListPages/University Courses/UniversityCourseList.vue")
const UniversityCourseAdd = () => import("../views/Gide/ListPages/University Courses/UniversityCourseAdd.vue")
const UniversityCourseEdit = () => import("../views/Gide/ListPages/University Courses/UniversityCourseEdit.vue")

// SPCM Calender
const SpcmCalenderList = () => import("../views/Gide/SPCM/SpcmCalenderList.vue")
const SpcmCalenderEdit = () => import("../views/Gide/SPCM/SpcmCalenderEdit.vue")

// const SpcmCalenderAdd = () => import("../views/Admin/Spcm/SpcmCalenderAdd.vue")
// const SpcmCalenderEdit = () => import("../views/Admin/Spcm/SpcmCalenderEdit.vue")

// Gpaths
const GPathDashboardV1 = () => import("../views/Gide/GPath/GPathWorkflowV1.1.vue")

const GPathProductPage = () => import("../views/Gide/GPath/GPathProductPage.vue")
const GPathReportGenerate = () => import("../views/Gide/GPath/GPathReportGenerate.vue")

// GPaths Roadmap
const GpathRoadmapList = () => import("../views/Gide/GPath/GpathRoadmapList.vue")
const GpathRoadmapAdd = () => import("../views/Gide/GPath/GpathRoadmapAdd.vue")
const GpathRoadmapEdit = () => import("../views/Gide/GPath/GpathRoadmapEdit.vue")

// GPaths Careerr
const GPathCareerList = () => import("../views/Gide/GPath/GPathCareerList.vue")
const GPathCareerAdd = () => import("../views/Gide/GPath/GPathCareerAdd.vue")
const GPathCareerEdit = () => import("../views/Gide/GPath/GPathCareerEdit.vue")

// GPaths Aptitude
const GPathAptitudeList = () => import("../views/Gide/GPath/GPathAptitudeList.vue")
const GPathAptitudeAdd = () => import("../views/Gide/GPath/GPathAptitudeAdd.vue")
const GPathAptitudeEdit = () => import("../views/Gide/GPath/GPathAptitudeEdit.vue")

// GPaths Career Inter
const GPathCareerInterList = () => import("../views/Gide/GPath/GPathCareerInterList.vue")
const GPathCareerInterAdd = () => import("../views/Gide/GPath/GPathCareerInterAdd.vue")
const GPathCareerInterEdit = () => import("../views/Gide/GPath/GPathCareerInterEdit.vue")

// GPaths Skill
const GPathSkillEdit = () => import("../views/Gide/GPath/GPathSkillEdit.vue")
const GPathSkillList = () => import("../views/Gide/GPath/GPathSkillList.vue")
const GPathSkillAdd = () => import("../views/Gide/GPath/GPathSkillAdd.vue")

// GPaths Career Type List
const GPathCareertypeList = () => import("../views/Gide/GPath/GPathCareertypeList.vue")
const GPathCareertypeAdd = () => import("../views/Gide/GPath/GPathCareertypeAdd.vue")
const GPathCareertypeEdit = () => import("../views/Gide/GPath/GPathCareertypeEdit.vue")

// GPaths Career Univ List
const GPathCareerUnivList = () => import("../views/Gide/GPath/GPathCareerUnivList.vue")
const GPathCareerUnivAdd = () => import("../views/Gide/GPath/GPathCareerUnivAdd.vue")
const GPathCareerUnivEdit = () => import("../views/Gide/GPath/GPathCareerUnivEdit.vue")

// GPATH SESSION
const GPathSessionList = () => import("../views/Gide/GPath/GPathSessionList.vue")
const GPathSchoolDashboard = () => import("../views/Gide/GPath/GPathSchoolDashboard.vue")
const GPathSessionAffiliateList = () => import("../views/Gide/GPath/GPathSessionAffiliateList.vue")
const GPathSessionEdit = () => import("../views/Gide/GPath/GPathSessionEdit.vue")

// Invite User
const InviteUser = () => import("../components/InviteUser.vue")

// GPATH ROADMAP EXTRA
const GPathrRoadmapExtraList = () => import("../views/Gide/GPath/GPathrRoadmapExtraList.vue")
const GPathrRoadmapExtraAdd = () => import("../views/Gide/GPath/GPathrRoadmapExtraAdd.vue")
const GPathrRoadmapExtraEdit = () => import("../views/Gide/GPath/GPathrRoadmapExtraEdit.vue")

// GPath Career Univ Scholarship List
const GPathCareerUnivScholarshipList = () => import("../views/Gide/GPath/GPathCareerUnivScholarshipList.vue")
const GPathCareerUnivScholarshipAdd = () => import("../views/Gide/GPath/GPathCareerUnivScholarshipAdd.vue")
const GPathCareerUnivScholarshipEdit = () => import("../views/Gide/GPath/GPathCareerUnivScholarshipEdit.vue")

// RESUME
const ResumeBuild = () => import("../views/Gide/Resume/ResumeBuild.vue")
const Resume = () => import("../views/Gide/Resume/Resume.vue")

// Mesh
const MeshInvite = () => import("../views/Gide/MeshInvite.vue")
const MeshDeepLink = () => import("../views/Gide/MeshDeepLink.vue")

// CustomReport
const CustomReportList = () => import("../views/Gide/CustomReport/CustomReportList.vue")
const CustomReportAdd = () => import("../views/Gide/CustomReport/CustomReportAdd.vue")
const CustomReportEdit = () => import("../views/Gide/CustomReport/CustomReportEdit.vue")

// Config Menu List
const ConfigMenuList = () => import("../views/Gide/ConfigMenu/ConfigMenuList.vue")
const ConfigMenuAdd = () => import("../views/Gide/ConfigMenu/ConfigMenuAdd.vue")
const ConfigMenuEdit = () => import("../views/Gide/ConfigMenu/ConfigMenuEdit.vue")

// user
const UserList = () => import("../views/Gide/User/UserList.vue")
const UserSearch = () => import("../views/Gide/User/UserSearch.vue")
const UserEdit = () => import("../views/Gide/User/UserEdit.vue")

// subscription Plans
const SubscriptionPlanList = () => import("../views/Gide/Subscription/SubscriptionPlanList.vue")
const SubscriptionPlanAdd = () => import("../views/Gide/Subscription/SubscriptionPlanAdd.vue")
const SubscriptionPlanEdit = () => import("../views/Gide/Subscription/SubscriptionPlanEdit.vue")

// Subscriptions
const SubscriptionList = () => import("../views/Gide/Subscription/SubscriptionList.vue")
const SubscriptionAdd = () => import("../views/Gide/Subscription/SubscriptionAdd.vue")
const SubscriptionEdit = () => import("../views/Gide/Subscription/SubscriptionEdit.vue")

// PayTransactions
const PayTransactionsListAdmin = () => import("../views/Gide/PayTransactions/PayTransactionsList.vue")
const PayTransactionsEdit = () => import("../views/Gide/PayTransactions/PayTransactionsEdit.vue")
const MyBillingHistory = () => import("../views/Gide/PayTransactions/MyBillingHistory.vue")

// B2B User lead registration
const UserRegLeadList = () => import("../views/Gide/UserRegLead/UserRegLeadList.vue")
const UserRegLeadEdit = () => import("../views/Gide/UserRegLead/UserRegLeadEdit.vue")

// Admin Notification
const AdminNotificationList = () => import("../views/Gide/AdminNotification/AdminNotificationList.vue")
const AdminNotificationAdd = () => import("../views/Gide/AdminNotification/AdminNotificationAdd.vue")
const AdminNotificationEdit = () => import("../views/Gide/AdminNotification/AdminNotificationEdit.vue")

// Admin Faq
const AdminFaqList = () => import("../views/Gide/AdminFaq/AdminFaqList.vue")
const AdminFaqAdd = () => import("../views/Gide/AdminFaq/AdminFaqAdd.vue")
const AdminFaqEdit = () => import("../views/Gide/AdminFaq/AdminFaqEdit.vue")

// Chat Bot Data Source
const ChatBotDataSrcList = () => import("../views/Gide/ChatBotDataSrc/ChatBotDataSrcList.vue")
const ChatBotDataSrcAdd = () => import("../views/Gide/ChatBotDataSrc/ChatBotDataSrcAdd.vue")
const ChatBotDataSrcEdit = () => import("../views/Gide/ChatBotDataSrc/ChatBotDataSrcEdit.vue")

// Chat Bot LLM
const ChatBotLLMList = () => import("../views/Gide/ChatBotLLM/ChatBotLLMList.vue")
const ChatBotLLMAdd = () => import("../views/Gide/ChatBotLLM/ChatBotLLMAdd.vue")
const ChatBotLLMEdit = () => import("../views/Gide/ChatBotLLM/ChatBotLLMEdit.vue")

// CustomForm
const CustomFormFieldsList = () => import("../views/Gide/CustomForm/CustomFormFieldsList.vue")
const CustomFormFieldsAdd = () => import("../views/Gide/CustomForm/CustomFormFieldsAdd.vue")
const CustomFormFieldsEdit = () => import("../views/Gide/CustomForm/CustomFormFieldsEdit.vue")

const CustomFormBuildList = () => import("../views/Gide/CustomForm/customFormBuildList.vue")
const customFormBuildEdit = () => import("../views/Gide/CustomForm/customFormBuildEdit.vue")

const TestPrep = () => import("../views/Gide/Resources/TestPrep.vue")
const AdvancePlacement = () => import("../views/Gide/Resources/AdvancePlacement.vue")

const userChildRoutes = (prop, mode) => [
  {
    path: "login",
    name: prop + ".login",
    meta: { requiresVisitors: true, name: "Login" },
    component: Login
  },
  {
    path: "register",
    name: prop + ".register",
    meta: { requiresVisitors: true, name: "Register" },
    component: Register
  },
  {
    path: "activate",
    name: prop + ".activate",
    meta: { requiresVisitors: true, name: "Activate" },
    component: Activate
  },
  {
    path: "activate/:code",
    name: prop + ".activate/:code",
    meta: { requiresVisitors: true, name: "Activate" },
    component: Activate
  },
  {
    path: "forgotPassword",
    name: prop + ".forgotPassword",
    meta: { requiresVisitors: true, name: "forgotPassword" },
    component: ForgotPassword
  },
  {
    path: "resetPassword",
    name: prop + ".resetPassword",
    meta: { requiresVisitors: true, name: "resetPassword" },
    component: ResetPassword
  },
  {
    path: "quick-profile",
    name: prop + ".QuickProfile",
    meta: { requiresVisitors: true, name: "QuickProfile" },
    component: QuickProfile
  },
  {
    path: "contactUsForm",
    name: prop + ".contact_us_form_student_and_parents",
    meta: { name: "ContactUsStudents" },
    component: ContactUsStudentsAdd
  },
  {
    path: "contactUs",
    name: prop + ".user_reg_lead_add",
    meta: { name: "ContactUs" },
    component: ContactUsOthersAdd
  },
  {
    path: "confirmation",
    name: prop + ".confirmation",
    meta: { name: "confirmation" },
    component: Confirmation
  },
  {
    path: "confirmation/:code/:pay_tid",
    name: prop + ".confirmation/:code",
    meta: { name: "confirmation" },
    component: Confirmation
  }
]

const childRoutes = (prop, mode) => [
  {
    path: "home",
    name: prop + ".list",
    meta: { requiresAuth: true, name: "Posts" },
    component: Posts
  },
  {
    path: "post/:post_id",
    name: prop + ".post/:postId",
    meta: { requiresAuth: true, name: "Post Detail" },
    component: PostDetail
  },
  {
    path: "clubs",
    name: prop + ".clubs",
    meta: { requiresAuth: true, name: "Clubs" },
    component: Clubs
  },
  {
    path: "/app_add",
    name: prop + ".app_add",
    meta: { auth: true, name: "Add Quiz" },
    component: AppAdd
  },
  {
    path: "event_list",
    name: prop + ".event_list",
    meta: { requiresAuth: true, name: "event List" },
    component: eventList
  },
  {
    path: "/quizBank_add/:app_id",
    name: prop + ".quizBank_add",
    meta: { auth: true, name: "Add QuizBank" },
    component: QuizBankAdd
  },
  {
    path: "/organisationCourse_edit/:cour_id",
    name: prop + ".organisationCourse_edit",
    meta: { auth: true, name: "Edit UniversityCourse", isAdmin: true },
    component: UniversityCourseEdit
  },
  {
    path: "/quizBank_list/:app_id",
    name: prop + ".quizBank_list",
    meta: { auth: true, name: "QuizBank List" },
    component: QuizBankList
  },
  {
    path: "/quizBank_edit/:qbk_id",
    name: prop + ".quizBank_edit",
    meta: { auth: true, name: "Edit QuizBank" },
    component: QuizBankEdit
  },
  {
    path: "/quiz_question_view/:qbk_id",
    name: prop + ".quiz_question_view",
    meta: { auth: true, name: "Quiz Question View" },
    component: QuestionQuizView
  },
  {
    path: "/spcmCalendar_list/:user_id",
    name: prop + ".spcmCalendar_list",
    meta: { auth: true, name: "SpcmCalender List" },
    component: SpcmCalenderList
  },
  {
    path: "/spcmCalendar_edit/:spcm_calendar_id",
    name: prop + ".spcmCalendar_edit",
    meta: { auth: true, name: "Edit SpcmCalender" },
    component: SpcmCalenderEdit
  },
  {
    path: "/thankyou",
    name: prop + ".thankyouPage",
    meta: { auth: true, name: "thankyouPage" },
    component: ThankYouPage
  },
  {
    path: "/thankyou",
    name: prop + ".thankyouPage/:code",
    meta: { auth: true, name: "thankyouPage" },
    component: ThankYouPage
  },
  {
    path: "/quiz_edit/:app_id",
    name: prop + ".quiz_edit",
    meta: { auth: true, name: "Edit Quiz" },
    component: AppEdit
  },
  {
    path: "/quiz_list",
    name: prop + ".quiz_list",
    meta: { auth: true, name: "Quiz List" },
    component: AppList
  },
  {
    path: "organisation_add",
    name: prop + ".organisation_add",
    meta: { requiresAuth: true, name: "Add Organisation" },
    component: OrganisationAdd
  },
  {
    path: "organisations/:org_type",
    name: prop + ".organisations",
    meta: { requiresAuth: true, name: "organisations" },
    component: OrganisationList
  },
  {
    path: "organisation_edit/:org_type/:org_id",
    name: prop + ".organisation_edit",
    meta: { requiresAuth: true, name: "Organisation Edit" },
    component: OrganisationEdit
  },
  {
    path: "organisations/:org_type/:org_id",
    name: prop + ".organisations",
    meta: { requiresAuth: true, name: "Organisations" },
    component: OrganisationDetails
  },
  {
    path: "dashboard",
    name: prop + ".list",
    meta: { requiresAuth: true, name: "OrgSaasDashboard" },
    component: OrgSaasDashboard
  },
  {
    path: "org_domain_validate/:org_type/:org_id",
    name: prop + ".organisations",
    meta: { requiresAuth: true, name: "OrgSaasDomainValidate" },
    component: OrgSaasDomainValidate
  },
  {
    path: "org_edit/:org_type/:org_id",
    name: prop + ".organisations",
    meta: { requiresAuth: true, name: "OrgSaasDomainValidate" },
    component: OrgSaasOrganisationEdit
  },
  {
    path: "login_preview/:org_type/:org_id",
    name: prop + ".organisations",
    meta: { requiresAuth: true, name: "OrgSaasDomainValidate" },
    component: OrgSaasLoginPagePreview
  },
  {
    path: "manage_users/:org_type/:org_id",
    name: prop + ".manage_users",
    meta: { requiresAuth: true, name: "ManageUsers" },
    component: ManageUsers
  },
  {
    path: "user/:user_id",
    name: prop + ".user",
    meta: { requiresAuth: true, name: "User" },
    component: UserProfile
  },
  {
    path: "/user_edit/:user_id",
    name: prop + ".user_edit",
    meta: { requiresAuth: true, name: "User Edit" },
    component: UserProfileEdit
  },
  {
    path: "club/:club_id",
    name: prop + ".club",
    meta: { requiresAuth: true, name: "Club" },
    component: ClubProfile
  },
  {
    path: "club_edit/:club_id",
    name: prop + ".club_edit",
    meta: { requiresAuth: true, name: "Club Edit" },
    component: ClubProfileEdit
  },
  {
    path: "club_create/:owner_module_obj_id",
    name: prop + ".club_create",
    meta: { requiresAuth: true, name: "Club Create" },
    component: ClubCreate
  },
  {
    path: "test_prep",
    name: prop + ".test_prep",
    meta: { requiresAuth: true, name: "Test Prep" },
    component: TPrepHome
  },
  {
    path: "test_prep_product",
    name: prop + ".test_prep_product",
    meta: { requiresAuth: true, name: "TestPrepProduct" },
    component: TPrepProduct
  },
  {
    path: "test_prep_target/:pdt_id",
    name: prop + ".test_prep_product_cuet",
    meta: { requiresAuth: true, name: "TestPrepProductSAT" },
    component: TPrepSpecificProduct
  },
  {
    path: "tprep_session_list",
    name: prop + ".tprep_session_list",
    meta: { auth: true, name: "TprepSession List" },
    component: TprepSessionList
  },
  {
    path: "tprep_session_add",
    name: prop + ".tprep_session_add",
    meta: { auth: true, name: "Add TprepSession" },
    component: TprepSessionAdd
  },
  {
    path: "tprep_session_edit/:tps_id",
    name: prop + ".tprep_session_edit",
    meta: { auth: true, name: "Edit TprepSession" },
    component: TprepSessionEdit
  },
  {
    path: "tprep_quiz_dashboard",
    name: prop + ".tprep_quiz_dashboard",
    meta: { auth: true, name: "TprepQuizDashboard" },
    component: TprepQuizDashboard
  },
  {
    path: "subjectModule_list",
    name: prop + ".subjectModule_list",
    meta: { auth: true, name: "TPrepSubjectModule List" },
    component: TPrepSubjectModuleList
  },
  {
    path: "subjectModule_add",
    name: prop + ".subjectModule_add",
    meta: { auth: true, name: "Add TPrepSubjectModule" },
    component: TPrepSubjectModuleAdd
  },
  {
    path: "subjectModule_edit/:subject_module_id",
    name: prop + ".subjectModule_edit",
    meta: { auth: true, name: "Edit TPrepSubjectModule" },
    component: TPrepSubjectModuleEdit
  },
  {
    path: "subject_modules/:subjId",
    name: prop + ".subject_modules",
    meta: { requiresAuth: true, name: "Home" },
    component: TPrepModuleList
  },
  {
    path: "result_list/:appId",
    name: prop + ".result_list",
    meta: { requiresAuth: true, name: "Results List" },
    component: TPrepResultsList
  },
  {
    path: "interest_list",
    name: prop + ".interest_list",
    meta: { auth: true, name: "Interest List" },
    component: InterestList
  },
  {
    path: "interest_add",
    name: prop + ".interest_add",
    meta: { auth: true, name: "Add Interest" },
    component: InterestAdd
  },
  {
    path: "interest_edit/:interest_id",
    name: prop + ".interest_edit",
    meta: { auth: true, name: "Edit Interest" },
    component: InterestEdit
  },
  {
    path: "interestCategory_list",
    name: prop + ".interestCategory_list",
    meta: { auth: true, name: "InterestCategory List" },
    component: InterestCategoryList
  },
  {
    path: "interestCategory_add",
    name: prop + ".interestCategory_add",
    meta: { auth: true, name: "Add InterestCategory" },
    component: InterestCategoryAdd
  },
  {
    path: "interestCategory_edit/:interest_cat_id",
    name: prop + ".interestCategory_edit",
    meta: { auth: true, name: "Edit InterestCategory" },
    component: InterestCategoryEdit
  },
  {
    path: "quiz_report_list/:appId",
    name: prop + ".result_list",
    meta: { requiresAuth: true, name: "Results List" },
    component: QuizReportList
  },
  {
    path: "/appResult_list/:app_id",
    name: prop + ".appResult_list",
    meta: { auth: true, name: "AppResult List" },
    component: AppResultList
  },
  {
    path: "/appResult_list_fix/:app_id/:user_id",
    name: prop + ".appResult_list_fix",
    meta: { auth: true, name: "AppResult List Fix" },
    component: AppResultListFix
  },
  {
    path: "event/:event_id",
    name: prop + ".event",
    meta: { requiresAuth: true, name: "Events" },
    component: eventView
  },
  {
    path: "event_add",
    name: prop + ".event_add",
    meta: { requiresAuth: true, name: "Event Add" },
    component: eventAdd
  },
  {
    path: "event_edit/:event_id",
    name: prop + ".event_edit",
    meta: { requiresAuth: true, name: "Event Edit" },
    component: eventEdit
  },
  {
    path: "events",
    name: prop + ".events",
    meta: { requiresAuth: true, name: "Events" },
    component: Events
  },
  {
    path: "subscription_plans",
    name: prop + ".subscription_plans",
    meta: { requiresAuth: true, name: "Subscription Plans" },
    component: SubscriptionPlans
  },
  {
    path: "subscription_addOns_plans",
    name: prop + ".subscription_plans",
    meta: { requiresAuth: true, name: "Subscription Plans" },
    component: SubscriptionPlans
  },
  {
    path: "my_billing_history/:user_id",
    name: prop + ".my_billing_history",
    meta: { auth: true, name: "My Billing History" },
    component: MyBillingHistory
  },
  {
    path: "payTransactions_list",
    name: prop + ".payTransactions_list",
    meta: { auth: true, name: "PayTransactions List" },
    component: PayTransactionsListAdmin
  },
  {
    path: "payTransactions_list/:student_id",
    name: prop + ".payTransactions",
    meta: { auth: true, name: "PayTransactions" },
    component: PayTransactionsListAdmin
  },
  {
    path: "payTransactions_edit/:pay_id",
    name: prop + ".payTransactions_edit",
    meta: { auth: true, name: "Edit Pay Transactions" },
    component: PayTransactionsEdit
  },
  {
    path: "pay_gw_status",
    name: prop + ".pay_gw_status",
    meta: { auth: true, name: "Paygw Status" },
    component: PaygwStatus
  },
  {
    path: "pay_gw",
    name: prop + ".pay_gw",
    meta: { auth: true, name: "Paygw" },
    component: Paygw
  },
  {
    path: "pay_gw/:moduleType/:moduleObjId",
    name: prop + ".pay_gw",
    meta: { auth: true, name: "Paygw" },
    component: Paygw
  },
  {
    path: "invoice",
    name: prop + ".invoice",
    meta: { auth: true, name: "invoice" },
    component: Invoice
  },
  {
    path: "careersList",
    name: prop + ".careerList",
    meta: { auth: true, name: "careersList" },
    component: CareerList
  },
  {
    path: "career_add",
    name: prop + ".career_add",
    meta: { auth: true, name: "Add Career", isAdmin: true },
    component: CareerAdd
  },
  {
    path: "career_edit/:carr_id",
    name: prop + ".career_edit",
    meta: { auth: true, name: "Edit Career", isAdmin: true },
    component: CareerEdit
  },
  {
    path: "career/:carr_id",
    name: prop + ".CareerDetail",
    meta: { auth: true, name: "CareerDetail" },
    component: CareerDetail
  },
  {
    path: "/organisationCourse_add",
    name: prop + ".organisationCourse_add",
    meta: { auth: true, name: "Add UniversityCourse", isAdmin: true },
    component: UniversityCourseAdd
  },
  {
    path: "admission_dashboard",
    name: prop + ".admission_dashboard",
    meta: { auth: true, name: "admission_dashboard" },
    component: AdmissionDashboard
  },
  {
    path: "admissionAnalyticsSchoolWise",
    name: prop + ".Admission_analytics_school_wise",
    meta: { auth: true, name: "Admission_analytics_school_wise" },
    component: AdmissionAnalyticsSchoolWise
  },
  {
    path: "Photos/:module_id/:type",
    name: prop + ".Photos",
    meta: { auth: true, name: "Photos", img: "" },
    component: Photos
  },
  {
    path: "admission/add/:orgId",
    name: prop + ".admission_add_org",
    meta: { auth: true, name: "admission_add_org" },
    component: Admission
  },
  {
    path: "admission/:admId/view",
    name: prop + ".admission_view",
    meta: { auth: true, name: "admission_view" },
    component: Admission
  },
  {
    path: "admission/:admId/edit",
    name: prop + ".admission_edit",
    meta: { auth: true, name: "admission_edit" },
    component: Admission
  },
  {
    path: "event_list",
    name: prop + ".event_list",
    meta: { auth: true, name: "eventList" },
    component: EventList
  },
  {
    path: "event_participant_dashboard/:eventId",
    name: prop + ".event_participant_report",
    meta: { auth: true, name: "event_participant_report" },
    component: EventParticipantReport
  },
  {
    path: "event_participant_dashboard/:eventId/SCH/:orgId",
    name: prop + ".event_participant_school_report",
    meta: { auth: true, name: "event_participant_school_report" },
    component: EventParticipantReport
  },
  {
    path: "event_analytic_school",
    name: prop + ".event_analytic_all_school",
    meta: { auth: true, name: "event_analytic_all_school" },
    component: EventReportSchoolWise
  },
  {
    path: "event_analytic_school/:orgId",
    name: prop + ".event_analytic_school",
    meta: { auth: true, name: "event_analytic_school" },
    component: EventReportSchoolWise
  },
  {
    path: "event_analytics_booth_checkin_report/:eventId",
    name: prop + ".event_analytics_booth_checkin_report",
    meta: { auth: true, name: "event_analytics_booth_checkin_report" },
    component: EventCheckinReport
  },
  {
    path: "SubcriptionReport",
    name: prop + ".SubscriptionReport",
    meta: { requiresAuth: false, name: "SubscriptionReport" },
    component: SubscriptionReport
  },
  {
    path: "EventCheckingForStudent",
    name: prop + ".EventCheckingForStudent",
    meta: { requiresAuth: true, name: "EventCheckingForStudent" },
    component: EventCheckingForStudent
  },
  {
    path: "followerReport",
    name: prop + ".analyticsFollowerCountReport",
    meta: { auth: true, name: "analyticsFollowerCountReport" },
    component: analyticsFollowerCountReport
  },
  {
    path: "followerReport/:orgId",
    name: prop + ".analyticsFollowerListReport",
    meta: { auth: true, name: "analyticsFollowerListReport" },
    component: analyticsFollowerListReport
  },
  {
    path: "question_quiz/:examId",
    name: prop + ".question_quiz",
    meta: { requiresAuth: true, name: "question_quiz" },
    component: QuestionQuiz
  },
  {
    path: "question_quizv1/:examId",
    name: prop + ".question_quiz",
    meta: { requiresAuth: true, name: "question_quiz" },
    component: QuestionQuizV1
  },
  {
    path: "quiz",
    name: prop + ".quiz",
    meta: { requiresAuth: true, name: "quiz" },
    component: QuizList
  },
  {
    path: "app_result_view/:appId/:userId/:showResultInfoMsg",
    name: prop + ".app_result_view",
    meta: { requiresAuth: true, name: "app_result_view" },
    component: QuizResultView
  },
  {
    path: "app_result_view_all/:appId/:userId/:attemptNo/:showResultInfoMsg",
    name: prop + ".app_result_view_all",
    meta: { requiresAuth: true, name: "app_result_view_all" },
    component: QuizResultView
  },
  {
    path: "analyse_app_result/:appId/:userId",
    name: prop + ".analyse_app_result",
    meta: { requiresAuth: true, name: "analyse_app_result" },
    component: AppAnswerExplanation
  },
  {
    path: "quiz_detail/:quizId",
    name: prop + ".quiz",
    meta: { requiresAuth: true, name: "quiz_detail" },
    component: QuizDetail
  },
  {
    path: "game_hangman/:app_id",
    name: prop + ".hangman",
    meta: { requiresAuth: true, name: "hangman" },
    component: hangman
  },
  {
    path: "game_crossword/:app_id",
    name: prop + ".crossword",
    meta: { requiresAuth: true, name: "crossword" },
    component: crossword
  },
  {
    path: "spcm",
    name: prop + ".spcm",
    meta: { requiresAuth: false, name: "spcm" },
    component: SpcmADD
  },
  {
    path: "spcm/:spcmId",
    name: prop + ".spcm",
    meta: { requiresAuth: false, name: "spcm" },
    component: SpcmADD
  },
  {
    path: "spcmDashboard",
    name: prop + ".spcmDashboard",
    meta: { requiresAuth: false, name: "spcmDashboard" },
    component: SpcmList
  },
  {
    path: "/spcm_edit/:spcm_id",
    name: prop + ".spcm_edit",
    meta: { auth: true, name: "Change Counsellor" },
    component: SpcmEdit
  },
  {
    path: "analytics_tracker_list",
    name: prop + ".analytics_tracker_list",
    meta: { auth: true, name: "analyticsTrackerList" },
    component: analyticsTrackerList
  },
  {
    path: "click_analytics",
    name: prop + ".click_analytics",
    meta: { auth: true, name: "clickAnalytics" },
    component: clickAnalytics
  },
  {
    path: "/custom_report_list",
    name: prop + ".custom_report_list",
    meta: { auth: true, name: "Custom Report List" },
    component: CustomReportList
  },
  {
    path: "/custom_report_add",
    name: prop + ".custom_report_add",
    meta: { auth: true, name: "Add Custom Report" },
    component: CustomReportAdd
  },
  {
    path: "/custom_report_edit/:cust_report_id",
    name: prop + ".custom_report_edit",
    meta: { auth: true, name: "Edit Custom Report" },
    component: CustomReportEdit
  },
  {
    path: "orgAccountDashboard",
    name: prop + ".orgAccountDashboard",
    meta: { auth: true, name: "orgAccountDashboard" },
    component: orgAccountDashboard
  },
  {
    path: "org_morderation",
    name: prop + ".org_morderation",
    meta: { auth: true, name: "org_morderation" },
    component: OrgModeration
  },
  {
    path: "/organisationCourse_list/:org_id",
    name: prop + ".organisationCourse_list",
    meta: { auth: true, name: "UniversityCourse List" },
    component: UniversityCourseList
  },
  {
    path: "/invite",
    name: prop + ".invite",
    meta: { auth: true, name: "Invite User" },
    component: InviteUser
  },
  {
    path: "gpath_home",
    name: prop + ".gpath_home",
    meta: { auth: true, name: "gpath_home" },
    component: GPathDashboardV1
  },
  {
    path: "gpaths",
    name: prop + ".gpath",
    meta: { auth: true, name: "gpath" },
    component: GPathProductPage
  },
  {
    path: "gpath_report_generate",
    name: prop + ".gpath_report_generate",
    meta: { auth: true, name: "gpath_report_generate" },
    component: GPathReportGenerate
  },
  {
    path: "/gpath_session_list",
    name: prop + ".gpath_session_list",
    meta: { auth: true, name: "GPathSession List" },
    component: GPathSessionList
  },
  {
    path: "/gpath_session_list/:user_id",
    name: prop + ".gpath_session_list",
    meta: { auth: true, name: "GPathSession List" },
    component: GPathSessionList
  },
  {
    path: "/gpath_school_dashboard/:org_id",
    name: prop + ".gpath_school_dashboard",
    meta: { auth: true, name: "GPathSchoolDashboard" },
    component: GPathSchoolDashboard
  },
  {
    path: "/affiliate_codes_dashboard",
    name: prop + ".affiliate_codes_dashboard",
    meta: { auth: true, name: "GPathSession Affiliates" },
    component: GPathSessionAffiliateList
  },
  {
    path: "/gpath_session_edit/:gps_id",
    name: prop + ".gpath_session_edit",
    meta: { auth: true, name: "Edit GPathSession" },
    component: GPathSessionEdit
  },
  {
    path: "/gpath_career_list",
    name: prop + ".gpath_career_list",
    meta: { auth: true, name: "GPathCareer List" },
    component: GPathCareerList
  },
  {
    path: "/gpath_career_add",
    name: prop + ".gpath_career_add",
    meta: { auth: true, name: "Add GPath Career" },
    component: GPathCareerAdd
  },
  {
    path: "/gpath_career_edit/:gpc_id",
    name: prop + ".gpath_career_edit",
    meta: { auth: true, name: "Edit GPath Career" },
    component: GPathCareerEdit
  },
  {
    path: "/gpath_aptitude_list",
    name: prop + ".gpath_aptitude_list",
    meta: { auth: true, name: "GPathAptitude List" },
    component: GPathAptitudeList
  },
  {
    path: "/gpath_aptitude_add",
    name: prop + ".gpath_aptitude_add",
    meta: { auth: true, name: "Add GPathAptitude" },
    component: GPathAptitudeAdd
  },
  {
    path: "/gpath_aptitude_edit/:gpa_id",
    name: prop + ".gpath_aptitude_edit",
    meta: { auth: true, name: "Edit GPathAptitude" },
    component: GPathAptitudeEdit
  },
  {
    path: "/gpath_career_inter_list",
    name: prop + ".gpath_career_inter_list",
    meta: { auth: true, name: "GPathCareerInter List" },
    component: GPathCareerInterList
  },
  {
    path: "/gpath_career_inter_add",
    name: prop + ".gpath_career_inter_add",
    meta: { auth: true, name: "Add GPathCareerInter" },
    component: GPathCareerInterAdd
  },
  {
    path: "/gpath_career_inter_edit/:gpci_id",
    name: prop + ".gpath_career_inter_edit",
    meta: { auth: true, name: "Edit GPathCareerInter" },
    component: GPathCareerInterEdit
  },
  {
    path: "/gpath_skill_list",
    name: prop + ".gpath_skill_list",
    meta: { auth: true, name: "GPathSkill List" },
    component: GPathSkillList
  },
  {
    path: "/gpath_skill_add",
    name: prop + ".gpath_skill_add",
    meta: { auth: true, name: "Add GPathSkill" },
    component: GPathSkillAdd
  },
  {
    path: "/gpath_skill_edit/:gps_id",
    name: prop + ".gpath_skill_edit",
    meta: { auth: true, name: "Edit GPathSkill" },
    component: GPathSkillEdit
  },
  {
    path: "/gpath_career_type_list/:name",
    name: prop + ".gpath_career_type_list",
    meta: { auth: true, name: "GPathCareertype List" },
    component: GPathCareertypeList
  },
  {
    path: "/gpath_career_type_add",
    name: prop + ".gpath_career_type_add",
    meta: { auth: true, name: "Add GPathCareertype" },
    component: GPathCareertypeAdd
  },
  {
    path: "/gpath_career_type_edit/:gpct_id",
    name: prop + ".gpath_career_type_edit",
    meta: { auth: true, name: "Edit GPathCareertype" },
    component: GPathCareertypeEdit
  },
  {
    path: "/gpath_career_univ_list",
    name: prop + ".gpath_career_univ_list",
    meta: { auth: true, name: "GPathCareerUniv List" },
    component: GPathCareerUnivList
  },
  {
    path: "/gpath_career_univ_add",
    name: prop + ".gpath_career_univ_add",
    meta: { auth: true, name: "Add GPathCareerUniv" },
    component: GPathCareerUnivAdd
  },
  {
    path: "/gpath_career_univ_edit/:gcu_id",
    name: prop + ".gpath_career_univ_edit",
    meta: { auth: true, name: "Edit GPathCareerUniv" },
    component: GPathCareerUnivEdit
  },
  {
    path: "/gpathRoadmap_list",
    name: prop + ".gpathRoadmap_list",
    meta: { auth: true, name: "GpathRoadmap List" },
    component: GpathRoadmapList
  },
  {
    path: "/gpathRoadmap_add",
    name: prop + ".gpathRoadmap_add",
    meta: { auth: true, name: "Add GpathRoadmap" },
    component: GpathRoadmapAdd
  },
  {
    path: "/gpathRoadmap_edit/:grmap_id",
    name: prop + ".gpathRoadmap_edit",
    meta: { auth: true, name: "Edit GpathRoadmap" },
    component: GpathRoadmapEdit
  },
  {
    path: "/gpathRoadmapExtra_list",
    name: prop + ".gpathRoadmapExtra_list",
    meta: { auth: true, name: "GPathrRoadmapExtra List" },
    component: GPathrRoadmapExtraList
  },
  {
    path: "/gpathRoadmapExtra_add",
    name: prop + ".gpathRoadmapExtra_add",
    meta: { auth: true, name: "Add GPathrRoadmapExtra" },
    component: GPathrRoadmapExtraAdd
  },
  {
    path: "/gpathRoadmapExtra_edit/:gprme_id",
    name: prop + ".gpathRoadmapExtra_edit",
    meta: { auth: true, name: "Edit GPathrRoadmapExtra" },
    component: GPathrRoadmapExtraEdit
  },
  {
    path: "/gpath_career_univ_scholarship_list",
    name: prop + ".gpath_career_univ_scholarship_list",
    meta: { auth: true, name: "GPathCareerUnivScholarship List" },
    component: GPathCareerUnivScholarshipList
  },
  {
    path: "/gpath_career_univ_scholarship_add",
    name: prop + ".gpath_career_univ_scholarship_add",
    meta: { auth: true, name: "Add GPathCareerUnivScholarship" },
    component: GPathCareerUnivScholarshipAdd
  },
  {
    path: "/gpath_career_univ_scholarship_edit/:gcus_id",
    name: prop + ".gpath_career_univ_scholarship_edit",
    meta: { auth: true, name: "Edit GPathCareerUnivScholarship" },
    component: GPathCareerUnivScholarshipEdit
  },
  {
    path: "/resume_builder",
    name: prop + ".resume",
    meta: { auth: true, name: "Resume" },
    component: ResumeBuild
  },
  {
    path: "/resume_view",
    name: prop + ".resume",
    meta: { auth: true, name: "Resume" },
    component: Resume
  },
  {
    path: "/chat_invite",
    name: prop + ".chat_invite",
    meta: { auth: true, name: "ChatInvite", requiresAuth: true },
    component: MeshInvite
  },
  {
    path: "/chat_deeplink",
    name: prop + ".chat_deeplink",
    meta: { auth: true, name: "ChatInvite", requiresAuth: true },
    component: MeshDeepLink
  },
  {
    path: "/config_menu_list/:config_menu_type",
    name: prop + ".config_menu_list",
    meta: { auth: true, name: "ConfigMenu List" },
    component: ConfigMenuList
  },
  {
    path: "/config_menu_add",
    name: prop + ".config_menu_add",
    meta: { auth: true, name: "Add ConfigMenu" },
    component: ConfigMenuAdd
  },
  {
    path: "/config_menu_edit/:acm_id",
    name: prop + ".config_menu_edit",
    meta: { auth: true, name: "Edit ConfigMenu" },
    component: ConfigMenuEdit
  },
  {
    path: "user_list",
    name: prop + ".user_list",
    meta: { auth: true, name: "User List", isAdmin: true },
    component: UserList
  },
  {
    path: "user_search/:user_data",
    name: prop + ".user_search",
    meta: { auth: true, name: "User Search" },
    component: UserSearch
  },
  {
    path: "user_search",
    name: prop + ".user_search",
    meta: { auth: true, name: "User Search" },
    component: UserSearch
  },
  {
    path: "user_edit/:user_id",
    name: prop + ".user_edit",
    meta: { auth: true, name: "User Edit", isAdmin: true },
    component: UserEdit
  },
  {
    path: "subscriptionPlan_list",
    name: prop + ".subscriptionPlan_list",
    meta: { auth: true, name: "Subscription List", isAdmin: true },
    component: SubscriptionPlanList
  },
  {
    path: "subscriptionPlan_add",
    name: prop + ".subscriptionPlan_add",
    meta: { auth: true, name: "Add Subscription", isAdmin: true },
    component: SubscriptionPlanAdd
  },
  {
    path: "subscriptionPlan_edit/:subs_plan_id",
    name: prop + ".subscriptionPlan_edit",
    meta: { auth: true, name: "Edit Subscription", isAdmin: true },
    component: SubscriptionPlanEdit
  },
  {
    path: "subscription_list",
    name: prop + ".subscription_list",
    meta: { auth: true, name: "Subscription List" },
    component: SubscriptionList
  },
  {
    path: "subscription_list/:student_id",
    name: prop + ".subscription",
    meta: { auth: true, name: "Subscription" },
    component: SubscriptionList
  },
  {
    path: "subscription_add",
    name: prop + ".subscription_add",
    meta: { auth: true, name: "Add Subscription" },
    component: SubscriptionAdd
  },
  {
    path: "subscription_edit/:subs_id",
    name: prop + ".subscription_edit",
    meta: { auth: true, name: "Edit Subscription" },
    component: SubscriptionEdit
  },
  {
    path: "contactus_list",
    name: prop + ".contactus_list",
    meta: { auth: true, name: "Contact Us List" },
    component: ContactUsList
  },
  {
    path: "user_reg_lead_list",
    name: prop + ".user_reg_lead_list",
    meta: { auth: true, name: "UserRegLead List" },
    component: UserRegLeadList
  },
  {
    path: "user_reg_lead_edit/:usrl_id",
    name: prop + ".user_reg_lead_edit",
    meta: { auth: true, name: "Edit UserRegLead" },
    component: UserRegLeadEdit
  },
  {
    path: "admin_notification_list",
    name: prop + ".admin_notification_list",
    meta: { auth: true, name: "AdminNotificationList" },
    component: AdminNotificationList
  },
  {
    path: "admin_notification_add",
    name: prop + ".admin_notification_add",
    meta: { auth: true, name: "AdminNotificationAdd" },
    component: AdminNotificationAdd
  },
  {
    path: "admin_notification_edit/:notification_id",
    name: prop + ".admin_notification_edit",
    meta: { auth: true, name: "AdminNotificationEdit" },
    component: AdminNotificationEdit
  },
  {
    path: "faq_list",
    name: prop + ".faq_list",
    meta: { auth: true, name: "faq_list" },
    component: AdminFaqList
  },
  {
    path: "faq_list/:gpaths",
    name: prop + ".faq_list",
    meta: { auth: true, name: "faq_list" },
    component: AdminFaqList
  },
  {
    path: "faq_list_dashboard",
    name: prop + ".faq_list_dashboard",
    meta: { auth: true, name: "faq_list_dashboard" },
    component: AdminFaqList
  },
  {
    path: "faq_add",
    name: prop + ".faq_add",
    meta: { auth: true, name: "faq_add" },
    component: AdminFaqAdd
  },
  {
    path: "faq_edit/:faq_id",
    name: prop + ".faq_edit",
    meta: { auth: true, name: "faq_edit" },
    component: AdminFaqEdit
  },
  {
    path: "chatbot_data_src_list",
    name: prop + ".chatbot_data_src_list",
    meta: { auth: true, name: "ChatBotDataSrc List" },
    component: ChatBotDataSrcList
  },
  {
    path: "chatbot_data_src_add",
    name: prop + ".chatbot_data_src_add",
    meta: { auth: true, name: "Add ChatBotDataSrc" },
    component: ChatBotDataSrcAdd
  },
  {
    path: "chatbot_data_src_edit/:cbds_id",
    name: prop + ".chatbot_data_src_edit",
    meta: { auth: true, name: "Edit ChatBotDataSrc" },
    component: ChatBotDataSrcEdit
  },
  {
    path: "chatbot_llm_list",
    name: prop + ".chatbot_llm_list",
    meta: { auth: true, name: "ChatBotLLM List" },
    component: ChatBotLLMList
  },
  {
    path: "chatbot_llm_add",
    name: prop + ".chatbot_llm_add",
    meta: { auth: true, name: "Add ChatBotLLM" },
    component: ChatBotLLMAdd
  },
  {
    path: "chatbot_llm_edit/:cbllm_id",
    name: prop + ".chatbot_llm_edit",
    meta: { auth: true, name: "Edit ChatBotLLM" },
    component: ChatBotLLMEdit
  },
  {
    path: "/customFormFields_list",
    name: prop + ".customFormFields_list",
    meta: { auth: true, name: "CustomFormFields List" },
    component: CustomFormFieldsList
  },
  {
    path: "/customFormFields_add",
    name: prop + ".customFormFields_add",
    meta: { auth: true, name: "Add CustomFormFields" },
    component: CustomFormFieldsAdd
  },
  {
    path: "/customFormFields_edit/:cff_id",
    name: prop + ".customFormFields_edit",
    meta: { auth: true, name: "Edit CustomFormFields" },
    component: CustomFormFieldsEdit
  },
  {
    path: "/customFormBuild_list",
    name: prop + ".customFormFields_edit",
    meta: { auth: true, name: "Edit CustomFormFields" },
    component: CustomFormBuildList
  },
  {
    path: "/customFormBuild_edit/:cfb_id",
    name: prop + ".customFormBuild_edit",
    meta: { auth: true, name: "Edit customFormBuild" },
    component: customFormBuildEdit
  },
  {
    path: "/testprep",
    name: prop + ".test_prep",
    meta: { auth: true, name: "TestPrep" },
    component: TestPrep
  },
  {
    path: "/advanceplacements",
    name: prop + ".advance_placement",
    meta: { auth: true, name: "AdvancePlacement" },
    component: AdvancePlacement
  }
]

const contentChildRoutes = (prop, mode) => [
  {
    path: "post/:post_id",
    name: prop + ".postContent",
    meta: { requiresAuth: false, name: "PostContent" },
    component: PostContent
  },
  {
    path: "Tnc",
    name: prop + ".Tnc",
    meta: { name: "Tnc" },
    component: Tnc
  },
  {
    path: "PrivacyPolicy",
    name: prop + ".PrivacyPolicy",
    meta: { name: "PrivacyPolicy" },
    component: PrivacyPolicy
  }
]

const publicChildRoutes = (prop, mode) => [
  {
    path: "GideEvents",
    name: prop + ".GideEvents",
    meta: { requiresAuth: false, name: "GideEvents" },
    component: GideEventList
  },
  {
    path: "GideEvent/:eventId",
    name: prop + ".GideEvent",
    meta: { requiresAuth: false, name: "GideEvent" },
    component: GideEventView
  },
  {
    path: "GideUniEvents",
    name: prop + ".GideEvents",
    meta: { requiresAuth: false, name: "GideUniEvents" },
    component: GideEventList
  },
  {
    path: "GideUniEvent/:eventId",
    name: prop + ".GideEvent",
    meta: { requiresAuth: false, name: "GideUniEvent" },
    component: GideEventView
  },
  {
    path: "GideViewCurrentEvent/:module_id",
    name: prop + ".GideViewCurrentEvent",
    meta: { requiresAuth: false, name: "GideViewCurrentEvent" },
    component: GideViewCurrentEvent
  },
  {
    path: "/affiliate/:eventId",
    name: prop + ".affiliate",
    meta: { auth: true, name: "List Affiliates" },
    component: AffiliateList
  },
  {
    path: "error",
    name: prop + ".error",
    meta: { name: "ErrorPage" },
    component: ErrorPage
  },
  {
    path: "error/:err",
    name: prop + ".error",
    meta: { name: "ErrorPage" },
    component: ErrorPage
  },
  {
    path: "gid/:user_id",
    name: prop + ".user",
    meta: { name: "User" },
    component: UserPublicProfile
  }
]

const webflowRoutes = (prop, mode) => [
  {
    path: "login",
    name: prop + ".login",
    meta: { requiresVisitors: true, name: "Login" },
    component: LoginV1
  },
  {
    path: "register",
    name: prop + ".register",
    meta: { requiresVisitors: true, name: "Register" },
    component: RegisterV1
  },
  {
    path: "quick-profile",
    name: prop + ".QuickProfile",
    meta: { requiresVisitors: true, name: "QuickProfile" },
    component: QuickProfileV1
  },
  {
    path: "forgotPassword",
    name: prop + ".forgotPasswordV1",
    meta: { requiresVisitors: true, name: "forgotPassword" },
    component: ForgotPasswordV1
  },
  {
    path: "contactUs",
    name: prop + ".user_reg_lead_addV1",
    meta: { requiresVisitors: true, name: "ContactUs" },
    component: ContactUsOthersAddV1
  },
  {
    path: "ContactUsV1",
    name: prop + ".ContactUsV1",
    meta: { name: "ContactUsV1" },
    component: WebFlowContactUsStudentsAdd
  },
  {
    path: "resetPasswordV1",
    name: prop + ".resetPassword",
    meta: { requiresVisitors: true, name: "resetPassword" },
    component: ResetPasswordV1
  },
  {
    path: "activate",
    name: prop + ".activateV1",
    meta: { requiresVisitors: true, name: "Activate" },
    component: ActivateV1
  },
  {
    path: "Activity/:activityId",
    name: prop + ".Activity",
    meta: { name: "Activity" },
    component: CustomActivityForm
  },
  {
    path: "GideEventsV1",
    name: prop + ".GideEvents",
    meta: { requiresAuth: false, name: "GideEvents" },
    component: GideEventList
  },
  {
    path: "GideEventV1/:eventId",
    name: prop + ".GideEvent",
    meta: { requiresAuth: false, name: "GideEvent" },
    component: GideEventView
  },
  {
    path: "faqs",
    name: prop + ".faq_list",
    meta: { name: "faq_list" },
    component: AdminFaqList
  }
]

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: GideAuthLayout,
    meta: { auth: true },
    children: userChildRoutes("auth")
  },
  {
    path: "/content",
    name: "content",
    component: ContentLayout,
    children: contentChildRoutes("content")
  },
  {
    path: "/public",
    name: "public",
    component: ContentLayout,
    children: publicChildRoutes("public")
  },
  {
    path: "/",
    name: "gide",
    component: GideLayout,
    children: childRoutes("gide")
  },
  {
    path: "/",
    name: "webflow",
    component: WebflowLayout,
    children: webflowRoutes("webflow")
  }
]

export default routes
