/*eslint-disable */
/**
 * @copyright    Copyright © 2019-2025 MiniVentureLab
 * @author       Alok Pabalkar  <alok@miniventurelab.com>
 * @package      Mesh Platform UI
 * @license      Proprietary
 * @description  Console
 *
 * Cache & Localstorage Get/Set Functionality
 */

import localforage from "localforage";
import iosBridge from './iosBridge';
import encryptAndDecrypt from '../Utils/encryptAnddecrypt.js'

localforage.config({
  driver: localforage.IndexedDB, // Force IndexedDB; same as using setDriver()
  name: process.env.VUE_APP_APP_TITLE, //App name
  version: 1.0,
  size: 209715200, // Size of database, in bytes. IndexedDB-only for now.
  storeName: 'gideStore', // Should be alphanumeric, with underscores.
  description: ''
});

var cache = {
  /**
   * set_current_user_id_ls
   * Save the currently selected user to Localstorage
   */
  set_current_user_id_ls(CURRENT_USER_ID) {
    let ls_key = "u";
    localStorage.setItem(ls_key, CURRENT_USER_ID);
  },
  /**
   * get_current_user_id_ls
   * Get the currently selected user froALTER TABLE `subscription_plan` ADD `subs_type` JSON NOT NULL AFTER `subs_plan_name`;m Localstorage
   */
  get_current_user_id_ls() {
    let ls_key = "u";
    return localStorage.getItem(ls_key);
  },

  /**
   * set_auth_token_ls
   * Save the token to Localstorage
   * @param token. Dont use save_to_device_storage() as this method does encoding which is not required
   */
  set_auth_token_ls(token) {
    let ls_key = "user_token";
    localStorage.setItem(ls_key, token);
  },
  /**
   * get_auth_token_ls
   * Get the token from Localstorage
   * @param token. Dont use get_from_device_storage() as this method does encoding which is not required
   */
  get_auth_token_ls() {
    let ls_key = "user_token";
    return localStorage.getItem(ls_key);
  },

  /**
   * set_user_data_ls
   * Save userdata to Localstorage
   * @param  user_data
   */
  set_user_data_ls(user_data) {
    let ls_key = "user_data";
    user_data = encryptAndDecrypt.aes_encrypt(ls_key, user_data)
    localStorage.setItem(ls_key, user_data);
  },
  /**
   * get_user_data_ls
   * Get userdata from Localstorage
   * @param  user_data
   */
  get_user_data_ls() {
    let ls_key = "user_data";
    try{
      let getItem = localStorage.getItem(ls_key);
      if(getItem) {
        let decryptData = encryptAndDecrypt.aes_decrypt(ls_key, getItem)
        return JSON.parse(decryptData)
      }
    }
    catch(ex){
      // When user is already logged in with the previous user_data credentials we make it to logout from the screen and relogin
      this.destroy_data_ls()
      localStorage.removeItem(ls_key)
      localStorage.removeItem("access_token")
      console.log("EX", ex)
    }
  },
  /**
   * setFcmPushToken_ls
   * Save Push Token to Localstorage
   * @param  user_data
   */
  setFcmPushToken_ls(fcm_push_token) {
    let ls_key = "fcm_push_token";
    localStorage.setItem(ls_key, JSON.stringify(fcm_push_token));
  },
  /**
   * getFcmPushToken_ls
   * Get Push Token from Localstorage
   * @param  user_data
   */
  getFcmPushToken_ls() {
    let ls_key = "fcm_push_token";
    return JSON.parse(localStorage.getItem(ls_key));
  },

  /**
   * set_google_auth_process_loader_ls
   * This methods sets the flag to indicate google login is in progress or stopped. Takes true or false
   */
  set_google_auth_process_loader_ls(loader) {
    return localStorage.setItem("google_auth_process_loader", loader);
  },
  /**
   * get_google_auth_process_loader_ls
   * get google auth process is in processing or not for purpose of show loader
   */
  get_google_auth_process_loader_ls() {
    return localStorage.getItem("google_auth_process_loader");
  },
    
  /**
   * set_selected_user_interests_ls
   */
  set_selected_user_interests_ls(selected_user_interests) {
    let ls_key = "selected_user_interests";
    localStorage.setItem(ls_key, JSON.stringify(selected_user_interests));
  },
  /**
   * get_selected_user_interests_ls
   */
  get_selected_user_interests_ls() {
    let ls_key = "selected_user_interests";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * set_asked_for_user_interests_ls
   */
  set_asked_for_user_interests_ls(asked_for_user_interests) {
    let ls_key = "asked_for_user_interests";
    localStorage.setItem(ls_key, asked_for_user_interests);
  },
  /**
   * get_asked_for_user_interests_ls
   */
  get_asked_for_user_interests_ls() {
    let ls_key = "asked_for_user_interests";
    return localStorage.getItem(ls_key);
  },
  /**
   * destroy_data_ls
   * Remove chats_data from Localstorage & IndexDB (localforage)
   */
  destroy_data_ls() {
    //This is to leave cookie_acceptance from being cleared when user logs out
    //Get cookie_acceptance value
    // let cookie_acceptance = null;
    // if (!window.webkit && !window.androidBridge) {
    //   cookie_acceptance = localStorage.getItem('cookie_acceptance');
    // }

    // localStorage.clear();
    // const exclude = {
    //   cookies: true
    // }
    // this.clearLocalStorage(exclude)
    const cookie = localStorage.getItem('cookies')
    localStorage.clear()
    localStorage.setItem('cookies',cookie);
    sessionStorage.clear();
    localforage.clear();

    // //Clear and Delete meshstore
    // localforage.clear();
    // localforage.dropInstance({
    //   name: process.env.VUE_APP_APP_TITLE, //App name
    // })
    // .then(function () {
    //   if (process.env.VUE_APP_DEBUG == "true") {console.log('Dropped DB - meshStore, ', process.env.VUE_APP_APP_TITLE);}
    // });

    //Set cookie_acceptance value again after logout
    // if (!window.webkit && !window.androidBridge) {
    //   localStorage.setItem('cookie_acceptance',cookie_acceptance);
    // }
  },
  /**
   * clear Local Storage except one
   */
  clearLocalStorage(exclude) {
    for (var i = 0; i < localStorage.length; i++){
      var key = localStorage.key(i);
      if (exclude[key]) {
        continue
      }
      localStorage.removeItem(key);
    }
  },

  /**
   * set_all_ posts
   * Save the list all post at Localstorage
   */
  async set_posts_list_ls(posts) {
    let ls_key = "posts";
    await cache.save_to_device_storage("set_posts_list_ls", ls_key, posts, "FS");
  },
  /**
   * get_all_ posts
   *  post at Localstorage
   */
  get_posts_ls() {
    let ls_key = "posts";
    return cache.get_from_device_storage("get_posts_ls", ls_key, "FS");
  },

  /**
   * set all post comment count
   */
  set_posts_comment_count_ls(countObj) {
    let ls_key = "comment_count";
    localforage.setItem(ls_key, countObj);
  },

  /**
   *  get all post comment count
  */
  get_post_comment_count_ls(){
    let ls_key = "comment_count";
    return localforage.getItem(ls_key);
  },

  /**
   * set New added post images 
  */
  set_post_add_image_ls(newPostImages){
    let ls_key = "new_post_images"
    localforage.setItem(ls_key, newPostImages)
  },
  
  /**
   * get New added post images 
  */
  get_post_add_image_ls(){
    let ls_key = "new_post_images"
    // return JSON.parse(localStorage.getItem(ls_key))
    return localforage.getItem(ls_key)
  },
  /**
   * save_to_device_storage
   * Save data to device Storage - Shared Preferences [SP] or App File System [FS]
   * storage_type: "SP", "FS"
   */
  async save_to_device_storage(parent_fn, ls_key, data, storage_type="FS") {
    //if (process.env.VUE_APP_DEBUG == "true") {console.log("save_to_device_storage:", parent_fn, ls_key, data);}

    data = await btoa(unescape(encodeURIComponent(JSON.stringify(data))));
    try {
      if(window.flutter_inappwebview){
        window.flutter_inappwebview.callHandler('setAppStorageItem',storage_type, ls_key, data);
      }
      else if (window.webkit) {
        return new Promise(function (resolve) {
          iosBridge.sendMessage({ "module": "setAppStorageItem", "data": { "storage_type":storage_type, "ls_key": ls_key, "ls_data": data } });
          resolve(true);
        });
      }
      else if (window.androidBridge) {
        await window.androidBridge.setAppStorageItem(storage_type, ls_key, data);
      }
      else {
        if(storage_type == "SP"){
          await localStorage.setItem(ls_key, data);
        }
        else if(storage_type == "FS"){
          await localforage.setItem(ls_key, data);
        }
      }
    }
    catch (error) {
      console.log("save_to_device_storage SetItem", parent_fn, error);
    }
  },
  /**
   * get_from_device_storage
   * Get data from device Storage - Shared Preferences [SP] or App File System [FS]
   * storage_type: "SP", "FS"
   */
  async get_from_device_storage(parent_fn, ls_key, storage_type) {
    let get_data = null;
    try {
      if(window.flutter_inappwebview){
        get_data = await window.flutter_inappwebview.callHandler('getAppStorageItem',storage_type, ls_key);
        console.log("getAppStorageItem = ",get_data);
      }
      else if (window.webkit) {
        get_data = await iosBridge.sendMessage({ "module": "getAppStorageItem", "data": {"storage_type":storage_type, "ls_key": ls_key} })
        get_data = get_data['data'];
      }
      else if (window.androidBridge) {
        get_data = await window.androidBridge.getAppStorageItem(storage_type, ls_key);
      }
      else {
        if(storage_type == "SP"){
          get_data = await localStorage.getItem(ls_key);
        }
        else{
          get_data = await localforage.getItem(ls_key);
        }
      }
    }
    catch (error) {
      console.error("get_from_device_storage GetItem", parent_fn, error,window.flutter_inappwebview);
    }

    try{
      if(get_data){
        get_data = await JSON.parse(decodeURIComponent(escape(atob(get_data))));
      }
    }
    catch (error) {
      console.log("Error get_from_device_storage, Decode", parent_fn, error);
      get_data = null;
    }

    //if (process.env.VUE_APP_DEBUG == "true") {console.log("get_from_device_storage:", parent_fn, ls_key, get_data);}

    return get_data;
  },
  /**
  * set_selectedPlan
  */
  async set_selectedPlan(selected_plan) {
    let ls_key = "selected_plan";
    localforage.setItem(ls_key, selected_plan);
  },
  /**
  * get_selectedPlan
  */
  async get_selectedPlan() {
    let ls_key = "selected_plan";
    return localforage.getItem(ls_key);
  },
  /**
  * set_MyPlan
  */
  async set_MyPlan(my_plan) {
    let ls_key = "my_plan";
    localforage.setItem(ls_key, my_plan);
  },
  /**
  * get_MyPlan
  */
  async get_MyPlan() {
    let ls_key = "my_plan";
    return localforage.getItem(ls_key);
  },
  /**
  * set_InvoicePlan
  */
  async set_InvoicePlan(my_plan) {
    let ls_key = "invoice_plan";
    localforage.setItem(ls_key, my_plan);
  },
  /**
  * set_SelectedModuleForPlan
  */
  async set_SelectedModuleForPlan(selected_module_plan) {
    let ls_key = "selected_module_for_plan";
    localforage.setItem(ls_key, selected_module_plan);
  },
  /**
  * get_InvoicePlan
  */
  async get_InvoicePlan() {
    let ls_key = "invoice_plan";
    return localforage.getItem(ls_key);
  },
  /**
  * get_SelectedModuleForPlan
  */
  async get_SelectedModuleForPlan() {
    let ls_key = "selected_module_for_plan";
    return localforage.getItem(ls_key);
  },
  /**
   * Get Date for date Filter
   */
  get_date_filter() {
    let ls_key = "date_filter";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set Date for date Filter
   */
  set_date_filter (date) {
    let ls_key = "date_filter";
    localStorage.setItem(ls_key, JSON.stringify(date));
  },

  /**
   * set_deep_link
   */
  set_deep_link (data) {
    let ls_key = "deeplink";
    localStorage.setItem(ls_key,data)
  },
  /**
   * get_deep_link
   */
  get_deep_link() {
    let ls_key = "deeplink";
    return localStorage.getItem(ls_key);
  },
  /**
   * delete_deep_link
   */
  delete_deep_link() {
    let ls_key = "deeplink";
    localStorage.removeItem(ls_key);
  },

  /**
   * set_user_source
   */
  set_user_source(data) {
    let ls_key = "source"
    localStorage.setItem(ls_key,data)
  },
  /**
   * get_user_source
   */
  get_user_source() {
    let ls_key = "source";
    return localStorage.getItem(ls_key);
  },
  /**
   * delete_user_source
   */
  delete_user_source() {
    let ls_key = "source";
    localStorage.removeItem(ls_key);
  },

  /**
   * set_questions_list
   */
  set_questions_list_ids (quizId, payload) {
    localStorage.setItem(`quiz_question_${quizId}`, JSON.stringify(payload))
  },
  /**
   * get_questions_list
   */
  get_questions_list_ids (quizId) {
    let data = localStorage.getItem(`quiz_question_${quizId}`)
    return data
  },
  /**
   * get_questions_list
   */
  remove_questions_list_ids (quizId) {
    localStorage.removeItem(`quiz_question_${quizId}`)
  },
  /**
   * set_user_quiz_response
   */
  set_user_quiz_response (quizId, params) {
    localStorage.setItem(`quiz_answer_resp_${quizId}`, params)
  },
  /**
   * remove_user_quiz_response
   */
  remove_user_quiz_response (quizId) {
    localStorage.removeItem(`quiz_answer_resp_${quizId}`)
  },
  /**
   * get_user_quiz_response
   */
  get_user_quiz_response (quizId) {
    let data = localStorage.getItem(`quiz_answer_resp_${quizId}`)
    return data
  },
  /**
   * get user role
   */
  get_user_role() {
    let ls_key = "user_role";
    return JSON.parse(localStorage.getItem(ls_key));

  },
  /**
   * Set user role
   */
  set_user_role (date) {
    let ls_key = "user_role";
    localStorage.setItem(ls_key, JSON.stringify(date));
  },
  /**
   * get plan amount
   */
  get_plan_amount() {
    let ls_key = "planAmount";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set plan amount
   */
  set_plan_amount (data) {
    let ls_key = "planAmount";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
   /**
   * get class
   */
   get_class() {
    let ls_key = "Class";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set class
   */
  set_class (data) {
    let ls_key = "Class";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get class
   */
  get_event_state() {
    let ls_key = "EventState";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set event state
   */
  set_event_state (data) {
    let ls_key = "EventState";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get event status
   */
  get_event_status() {
    let ls_key = "EventStatus";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set event status
   */
  set_event_status (data) {
    let ls_key = "EventStatus";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get subject name
   */
  get_subject_module() {
    let ls_key = "TPrepSubjectModule";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set subject status
   */
  set_subject_module (data) {
    let ls_key = "TPrepSubjectModule";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get intrest
   */
  get_intrest() {
    let ls_key = "Intrest";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set intrest
   */
  set_intrest (data) {
    let ls_key = "Intrest";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get welcome
   */
  get_welcome() {
    let ls_key = "Welcome";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set welcome
   */
  set_welcome (data) {
    let ls_key = "Welcome";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get quiz type
   */
  get_quiz_type() {
    let ls_key = "QuizType";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set quiz type
   */
  set_quiz_type (data) {
    let ls_key = "QuizType";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
   /**
   * get career
   */
   get_career() {
    let ls_key = "Career";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set career
   */
  set_career (data) {
    let ls_key = "Career";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get organisation
   */
   get_organisation () {
    let ls_key = "organisation";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set organisation
   */
  set_organisation (data) {
    let ls_key = "organisation";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get university
   */
  get_university () {
    let ls_key = "university";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set university
   */
  set_university (data) {
    let ls_key = "university";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get school
   */
  get_school () {
    let ls_key = "school";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set school
   */
  set_school (data) {
    let ls_key = "school";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get user_interest
   */
  get_user_interest () {
    let ls_key = "user_interest";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set user_interest
   */
  set_user_interest (data) {
    let ls_key = "user_interest";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get GPaths Session data
   */
  get_gpath_session () {
    let ls_key = "gpath_session";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set GPaths Session data
   */
  set_gpath_session (data) {
    let ls_key = "gpath_session";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get_mesh_url
   */
  get_mesh_url () {
    let ls_key = "mesh_url";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * set_mesh_url
   */
  set_mesh_url (data) {
    let ls_key = "mesh_url";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * get_open_mesh
   */
   get_open_mesh () {
    let ls_key = "open_mesh";
    return JSON.parse(localStorage.getItem(ls_key));
   },
   /**
    * set_open_mesh
    */
    set_open_mesh (data) {
      let ls_key = "open_mesh";
      localStorage.setItem(ls_key, JSON.stringify(data));
    },
  /**
   * get_open_mesh
   */
    get_saasOrgData () {
      let ls_key = "saasOrgData";
      return JSON.parse(localStorage.getItem(ls_key));
    },
   /**
    * set_open_mesh
    */
    set_saasOrgData (data) {
      let ls_key = "saasOrgData";
      localStorage.setItem(ls_key, JSON.stringify(data));
    },
  /**
   * get Country data
   */
  get_country_list () {
    let ls_key = "country_list";
    return JSON.parse(localStorage.getItem(ls_key));
  },
  /**
   * Set Country data
   */
  set_country_list (data) {
    let ls_key = "country_list";
    localStorage.setItem(ls_key, JSON.stringify(data));
  },
  /**
   * delete GPaths session data
   */
  delete_gpath_session() {
    let ls_key = "gpath_session"
    localStorage.removeItem(ls_key)
  }
}; /**End of Cache Object */

export default cache;
